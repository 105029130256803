<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper"></div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="订单号" prop="after_sale.order.order_sn" />
                <el-table-column label="审批状态">
                    <template #default="scope">
                        <template v-if="scope.row.status === 0">待审批</template>
                        <template v-if="scope.row.status === 1">已完成</template>
                        <template v-if="scope.row.status === 2">未通过</template>
                    </template>
                </el-table-column>
                <el-table-column label="申请用户" prop="after_sale.user.name" />
                <el-table-column label="备注" prop="remark" />
                <el-table-column label="审批时间" prop="status_at" />
                <el-table-column label="操作" width="220">
                    <template #default="scope">
                        <el-button
                            :disabled="scope.row.status === 1 || scope.row.status === 2"
                            plain
                            size="mini"
                            type="success"
                            @click="openApprovalDialog(scope.row.id, 1)"
                        >
                            通过
                        </el-button>
                        <el-button
                            :disabled="scope.row.status === 1 || scope.row.status === 2"
                            plain
                            size="mini"
                            type="danger"
                            @click="openApprovalDialog(scope.row.id, 2)"
                        >
                            驳回
                        </el-button>
                        <el-button plain size="mini" @click="openDetailDialog(scope.row.id)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <goods-approval-detail :id="detailDialog.id" v-model:show="detailDialog.show" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../../hooks/useCommonList";
import GoodsApprovalDetail from "./GoodsApprovalDetail.vue";
import { GET_GOODS_APPROVAL_LIST, UPDATE_GOODS_APPROVAL_STATUS } from "@/api/goods";
import { ElMessageBox } from "element-plus";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([{ prop: "status", type: "select", label: "售后状态" }]);

let searchOptions = ref({
    status: [
        { label: "全部", value: 0 },
        { label: "已完成", value: 1 },
        { label: "待审批|未通过", value: 2 }
    ]
});

let searchForm = ref({
    status: 0
});

// 获取数据
let getDataList = () => {
    GET_GOODS_APPROVAL_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 查看详情
let detailDialog = ref({
    show: false,
    id: null
});

let openDetailDialog = (id) => {
    detailDialog.value = {
        show: true,
        id: id
    };
};

// 审批
let openApprovalDialog = (id, status) => {
    ElMessageBox.prompt(status === 1 ? "请填写通过原因" : "请填写驳回原因", status === 1 ? "审批通过" : "审批驳回", {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
    })
        .then(({ value }) => {
            UPDATE_GOODS_APPROVAL_STATUS({
                id,
                status,
                remark: value
            }).then((res) => {
                getDataList();
            });
        })
        .catch(() => {});
};
</script>
